import React from "react"
import * as styles from "./Technologiesweuse.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"

const data = [
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_391c160bf3.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_ad100c1f7e.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_2296aacbf8.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/shielf_republic_777dcfac3d.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5_efc36a42f4.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_1_458d4df155.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5e8bdd18b3.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_3b1f295a77.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_8_90b80d2ede.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9_7f35999064.png",
  },
]

const TrustInvoZone = ({ strapiData }) => {
  return (
    <div className={styles.Trust}>
      <div className={styles.redline}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
          }
          className="w-100 h-100"
          alt="InvoGames"
        />
      </div>
      <Container>
        <Row>
          {strapiData?.cards?.map((card, index) => (
            <Col key={index} xl={3} md={3} xs={12}>
              <p className={styles.firstHeading}>{card.title}</p>
              <span className={styles.firstSpan}>{card?.subtitle}</span>
            </Col>
          ))}
          <Col xl={6} md={6} xs={12}>
            {/* <p
              className={styles.firstHeading}
              dangerouslySetInnerHTML={{ __html: card?.subtitle }}
            /> */}
            <div className={styles.firstSpan}>
              {strapiData?.cards[2]?.jsonData?.caseServices?.invocom_caseStudy?.skills?.map(
                (skill, index) => (
                  <span key={index} className={styles.roundedButton}>
                    {skill}
                  </span>
                )
              )}
            </div>
          </Col>
        </Row>
        <hr className={styles.line} />
      </Container>
      {/* <Container>
        <div className={styles.border}>
          <p className={styles.grow}>
            <span> 160,000+ </span> customers in over <span> 120 </span>
            countries grow their businesses.
          </p>
          <Row className={`${styles.rowGap}`}>
            {data.map((item, index) => (
              <div className={styles.logo}>
                <img src={item.logos} alt={`Client logo ${index + 1}`} />
              </div>
            ))}
          </Row>
        </div>
      </Container>*/}
    </div>
  )
}

export default TrustInvoZone
